import { useState, useEffect } from "react";
import Server from "./../Server";

export default function Registration(){
    const [users, setUsers] = useState([]);
    useEffect(()=>{
        Server.post("/index.php", {Key: "Registration"}).then(res=>{setUsers(res.data)})
        console.log(users)
    }, [])

    return(
        <section>
            <table className="table table-light table-striped">
                <thead>
                    <tr>
                        <th scope="col">
                            Id
                        </th>
                        <th>
                            Full Name
                        </th>
                        <th>
                            Telegram Handle
                        </th>
                        <th>
                            Email
                        </th>                   
                        <th>
                            Phone
                        </th>
                        <th>
                            Gender
                        </th>
                        <th>
                            Date of Birth
                        </th>
                        <th>
                            Address
                        </th>
                        <th>
                            About
                        </th>
                        <th>
                            Images
                        </th>
                        <th>
                            Registered
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {                      
                        users != null && users.map(item=>{
                            return(
                                <tr key={item.Id}>
                                    <td>{item.Id}</td>
                                    <td>{item.FullName}</td>
                                    <td>{item.TelegramHandle}</td>
                                    <td>{item.Email}</td>
                                    <td>{item.Phone}</td>
                                    <td>{item.Gender}</td>
                                    <td>{item.Dob}</td>
                                    <td>{item.Address}</td>
                                    <td>{item.About}</td>
                                    <td>
                                            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                                <div class="carousel-inner">
                                                    {
                                                        item.Files.split(",").map((elem, index)=>{
                                                            if(elem !== ""){
                                                                return(
                                                                    <section className={index == 0 ? "carousel-item active" : "carousel-item"}>                                                                       
                                                                        <img src={`https://team1722.future-dev.online/Server/Registers/${item.TelegramHandle}/${elem}`} className="d-block" alt="..." style={{width:"200px", height:"200px"}}/>                                                                     
                                                                    </section>
                                                                )
                                                            }
                                                        })
                                                    }                                             
                                                </div>
                                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span class="visually-hidden">Previous</span>
                                                </button>
                                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span class="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                    </td>
                                    <td>{item.Registered}</td>
                                </tr>
                            );
                        })                    
                    }
                    {
                        users == null && <tr><td>Loading...</td><td>Loading...</td><td>Loading...</td><td>Loading...</td><td>Loading...</td><td>Loading...</td></tr>
                    }
                </tbody>
            </table>
        </section>
    );
}