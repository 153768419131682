import Server from "./../Server";
import {useState, useEffect} from "react"
export default function Archives(){
    const [archives, setArchives] = useState([]);
    useEffect(()=>{
        Server.post("/index.php", {Key: "Archives"}).then(res=>{setArchives(res.data)})
        console.log(archives)
    }, [])

    const removeArchive = async (id, name)=>{
        const data = {Id: id, Name: name}
        const response = await Server.post("/upload.php", {...data, Key: "DeleteFromArchive"});
        if(response.data === "File and database record deleted successfully."){
            window.location.reload();
        }
    }

    return(
        <section>
            <table className="table table-success table-striped">
                <caption style={{captionSide: "top"}}><button className="btn btn-success" onClick={()=>{window.location = '/Uploader'}}>Upload an Archive</button></caption>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>File Name</th>
                        <th>Size</th>
                        <th>Uploaded Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        archives != null && archives.map(item=>{
                            return(
                              <tr key={item.Id}>
                                <td>{item.Id}</td>
                                <td>{item.Title}</td>
                                <td>{item.FileName}</td>
                                <td>{item.Size}</td>
                                <td>{item.UploadDate}</td>
                                <td>
                                    <button className="btn btn-danger bg-danger text-white border" onClick={()=>{removeArchive(item.Id, item.FileName)}}>X</button>
                                    <a className="btn btn-success" href={`https://team1722.future-dev.online/File/${item.FileName}`} download>Download</a>
                                </td>
                              </tr>  
                            );
                        })
                    }
                </tbody>
            </table>
        </section>
    );
}