import { useState } from 'react';
import Server from './../Server';

export default function Uploader() {
    const [title, setTitle] = useState(''); // Use separate state for title
    const [file, setFile] = useState(null); // Use separate state for file

    // Handles changes for the title input
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    // Handles changes for the file input
    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // Store only the first file
    };

    const handleSubmit = async () => {
        const fileData = new FormData();
        fileData.append("Title", title);
        if (file) {
            fileData.append("File", file);
        }
        fileData.append("Key", "Upload");
    
        // Perform the POST request with FormData
        try {
            const response = await fetch("https://team1722.future-dev.online/Server/upload.php", {method:"POST", body:fileData});
            window.location = "./"
        } catch (error) {
        }
    };
    

    return (
        <section style={{height:"100vh"}} className='d-flex justify-content-center align-items-center'>
            <section className="form-control" style={{width: "40%"}}>
                <section className="form-floating">
                    <input 
                        id="title" 
                        name="Title" 
                        onChange={handleTitleChange} 
                        placeholder="Enter Title" 
                        value={title} 
                        className="form-control"
                    />
                    <label htmlFor="title">Title</label>
                </section>
                <section className="form-floating">
                    <input 
                        type="file" 
                        accept=".zip" 
                        id="file" 
                        name="File" 
                        onChange={handleFileChange} 
                        className="form-control"
                    />
                    <label htmlFor="file">File</label>
                </section>
                <button className="btn btn-success mt-2" onClick={handleSubmit}>Upload</button>
                <button className='btn btn-primary ms-1 mt-2' onClick={()=>{window.location = "./"}}>Home</button>
            </section>
        </section>
    );
}
