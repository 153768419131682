import './App.css';
import { CheckUserLogin, GetUserRole } from './Functions/localStorage';
import { useEffect, useState } from 'react';
import Users from './Pages/Users';
import Archives from "./Pages/Archives";
import Registration from './Pages/Registration';
function App() {
  // UseStates
  const [selectedPage, setSelectedPage] = useState(<Users/>);

  // Functions
  useEffect(()=>{
    switch(localStorage.getItem("chosenPage")){
      case "Users":
        setSelectedPage(<Users/>);
        break;
      case "Archives":
        setSelectedPage(<Archives/>);
        break;
      case "Registers":
        setSelectedPage(<Registration/>);
    }
  }, [])
  const ChosePage = (e)=>{
    const {name} = e.target;
    switch(name){
      case "Users":
        setSelectedPage(<Users/>);
        localStorage.setItem("chosenPage", "Users");
        break;
      case "Archives":
        setSelectedPage(<Archives/>);
        localStorage.setItem("chosenPage", "Archives");
        break;
      case "Registers":
        setSelectedPage(<Registration/>);
        localStorage.setItem("chosenPage", "Registers");
    }
  }

  if(!CheckUserLogin()){
    window.location = "/Login";
  }
  if(GetUserRole() == "Regular"){
    return(
      <section>
        Please Wait for an admin
      </section>
    )
  }else{    
    return (
      <div className="App">
        <aside className='d-flex flex-column align-items-center justify-content-between'>
          <section className='d-flex flex-column'>
            <button className='btn btn-primary' onClick={ChosePage} name="Users">Users</button>
            <button className='btn btn-primary' onClick={ChosePage} name="Archives">Archives</button>
            <button className='btn btn-primary' onClick={ChosePage} name="Registers">Registers</button>
          </section>
          <button className='btn btn-warning mb-2' onClick={()=>{localStorage.clear(); window.location.reload()}}>Logout</button>
        </aside>
        <main className='ps-2 pt-2'>
          {selectedPage}
        </main>
      </div>
    );
  }
}

export default App;
