import React, { useState } from 'react';
import Server from "./../Server";

export default function Signup() {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [formData, setFormData] = useState({
    username: '',
    password:'',
    confirmPassword:'',
    Phone : '',
    gender: "Male",
    dob : ''
  });

  const [error, setError] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();
    
    if(formData.password.length < 8){
      setError("Your password must be at least 8 characters!");
      return;
    }
    if(formData.password !== formData.confirmPassword){
      setError("The password does not match confirm password!");
      return;
    }
    const checkUsernameExists = await Server.post("/index.php", {...formData, Key: 'CheckUserExists'});

    if(checkUsernameExists.data[0] === 'undefined'){
      setError("Username already taken.")
      return;
    }

    const signup = await Server.post("/index.php", {formData, Key: "Signup"});
    if(signup.data === "Accepted"){
      localStorage.setItem('Username', formData.username);
      window.location="./";
    }

    setError("");
  };

  return (
    <section style={{height:"100vh"}} className='d-flex justify-content-center align-items-center'>

    <section style={{width:"40%"}}>
      <h2>Signup</h2>
      <p style={{color:"red"}}>{error}</p>
      <form onSubmit={handleSignup} className="form-control">
      <section className="form-floating">
          <input
            type="text"
            name='username'
            value={formData.username}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Username:</label>
        </section>
        <section className="form-floating">
          <input
            type="password"
            name='password'
            value={formData.password}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Password:</label>
        </section>
        <section className="form-floating">
          <input
            type="password"
            name='confirmPassword'
            value={formData.confirmPassword}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Confirm Password:</label>
        </section>
        <section className="form-floating">
          <input
            type="text"
            name='Phone'
            value={formData.Phone}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Phone:</label>
        </section>
        <section className="form-floating">
          <select className="form-control" name="gender" onChange={handleChange}>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          <label>Gender:</label>
        </section>
        <section className="form-floating">
        <input
            type="date"
            name='dob'
            value={formData.dob}
            onChange={handleChange}
            className="form-control"
            required         
            />
            <label>Date of Birth:</label>
        </section>
        
        <button className="btn btn-primary mt-2" type="submit">Signup</button>
        <p>Already have an account? <a href='Login'>Login</a></p>
      </form>
    </section>
    </section>
  );
}
