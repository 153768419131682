import { useState, useEffect } from "react";
import Server from "./../Server";

export default function Users(){
    const [users, setUsers] = useState([]);
    useEffect(()=>{
        Server.post("/index.php", {Key: "Users"}).then(res=>{setUsers(res.data)})
        console.log(users)
    }, [])

    return(
        <section>
            <table className="table table-success table-striped">
                <thead>
                    <tr>
                        <th scope="col">
                            Id
                        </th>
                        <th>
                            Username
                        </th>
                        <th>
                            Role
                        </th>
                        <th>
                            Date of Birth
                        </th>
                        <th>
                            Gender
                        </th>
                        <th>
                            Phone
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {                      
                        users != null && users.map(item=>{
                            return(
                                <tr key={item.Id}>
                                    <td>{item.Id}</td>
                                    <td>{item.Username}</td>
                                    <td>{item.Role}</td>
                                    <td>{item.Dob}</td>
                                    <td>{item.Gender}</td>
                                    <td>{item.Phone}</td>
                                    <td><button className="btn btn-danger bg-danger text-white border">X</button></td>
                                </tr>
                            );
                        })                    
                    }
                    {
                        users == null && <tr><td>Loading...</td><td>Loading...</td><td>Loading...</td><td>Loading...</td><td>Loading...</td><td>Loading...</td></tr>
                    }
                </tbody>
            </table>
        </section>
    );
}