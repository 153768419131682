import React, { useState } from 'react';
import Server from "./../Server";

export default function Login() {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [formData, setFormData] = useState({
    email: '',
    password:'',
    remember:'',
  });
  const [loginErr, setLoginErr] = useState("none");

  const handleLogin = async (e) => {
    e.preventDefault();
    try{
      const send = await Server.post("/index.php", {...formData, Key: "Login"});
      send.data ? setLoginErr("none") : setLoginErr("block");
      let sessions = send.data;
      console.log(sessions)
      localStorage.setItem("Username", sessions.Username);
      localStorage.setItem("Name", sessions.Name);
      localStorage.setItem("Phone", sessions.Phone);
      localStorage.setItem("Dob", sessions.Dob);
      localStorage.setItem("Role", sessions.Role);
      if(sessions === false){

      }else{
        if(sessions.Username){
          window.location = "./";   
        }
            
      }
    }catch(err){
      console.log(err)
    }
    // Add authentication logic here
    // Typically, you would make an API request to verify credentials

    // If authentication is successful, redirect the user
  };

  return (
    <section style={{height:"100vh"}} className='d-flex justify-content-center align-items-center'>
      <section style={{width:"40%"}}>
      <h2>Login</h2>
      <p style={{color:"red", display:loginErr}}>Username or Password is wrong!</p>
      <form onSubmit={handleLogin} className="form-control">
        <section className="form-floating">
          <input
            type="text"
            name='email'
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Email or Username:</label>
        </section>
        <section className="form-floating">
          <input
            type="password"
            name='password'
            value={formData.password}
            onChange={handleChange}
            className="form-control"
            placeholder="i"
            required
            />
            <label>Password:</label>
        </section>
        <section>
            <input type='checkbox' onChange={handleChange} name='remember' value={formData.remember}/>
            <label htmlFor='remember'>Remember this device?</label>
        </section>
        <button className="btn btn-primary mt-2" type="submit">Login</button>
        <p>Don't have an account? <a href='Signup'>Creat one</a></p>
      </form>
      </section>
    </section>
  );
}
